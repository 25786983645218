
import {
  ChargeTypeExtra,
  PitchTypeExtra,
} from '../../apps/campsite-details/types'
import { MoneyRaw, MoneyType, toMoneyType } from '../../utility/money'
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

@Component
export default class BaseExtra extends Vue {
  @Prop({ required: true })
    extra: ChargeTypeExtra | PitchTypeExtra

  get isCompulsory() {
    return this.extra.compulsory
  }
  get isUnlimited() {
    return this.extra.max === null || this.extra.max === Infinity
  }

  isFree(price: MoneyRaw | MoneyType) {
    if (!price) return
    price = toMoneyType(price)
    return price && price.getAmount() === 0
  }

  getPopoverContent(description: string, terms: string[] | null) {
    let content = ''
    if (description) {
      content += description
    }
    if (terms) {
      for (const term of terms) {
        content += term + '<br/>'
      }
    }

    return content
  }
}
