
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

@Component({
  components: {
    portal: () => {
      if (process.client) {
        return import(
          /* webpackChunkName: "vue-portal", webpackMode: "lazy" */ 'portal-vue'
        ).then(({ Portal }) => Portal)
      } else {
        return
      }
    },
  },
})
export default class PuPopover extends Vue {
  showPopover: boolean = false

  @Prop({ type: String })
    popoverContent!: string

  togglePopover() {
    this.showPopover = !this.showPopover
  }

  closePopover() {
    this.showPopover = false
  }
}
