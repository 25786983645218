import {
  assign,
  createMachine,
  ErrorPlatformEvent,
  interpret,
  Interpreter,
} from 'xstate'
import sentryCapture from '~/utility/sentryCapture'

const baseURL = process.client
  ? ''
  : process.env.NUXT_AXIOS_BASE_URL || 'http://varnish_plus:8100'

interface GoogleTranslateContext {
  originalTexts: string[]
  translatedTexts: string[]
}

type GoogleTranslateEvent =
  | {
    type: 'TRANSLATE'
    format: 'text' | 'html'
    texts: string[]
    targetLang: string
  }
  | { type: 'SHOW_ORIGINAL' }

export interface GoogleTranslateState {
  states: {
    showingOriginal: {}
    translating: {}
  }
}

export function returnGoogleTranslateMachine() {
  return createMachine<GoogleTranslateContext, GoogleTranslateEvent>(
    {
      id: 'googleTranslateMachine',
      context: {
        originalTexts: [],
        translatedTexts: [],
      },
      initial: 'showingOriginal',
      states: {
        showingOriginal: {
          on: {
            TRANSLATE: [
              {
                target: 'showingTranslated',
                cond: ({ translatedTexts }) => translatedTexts.length > 0,
              },
              {
                actions: assign({
                  originalTexts: (_, { texts }) => texts,
                }),
                target: 'translating',
              },
            ],
          },
        },
        translating: {
          invoke: {
            src: 'translate',
            onDone: {
              target: 'showingTranslated',
              actions: assign({
                translatedTexts: (_, { data }) => data,
              }),
            },
            onError: {
              actions: 'logError',
              target: 'error',
            },
          },
        },
        showingTranslated: {
          on: {
            SHOW_ORIGINAL: 'showingOriginal',
          },
        },
        error: {},
      },
    },
    {
      actions: {
        logError: () => {
          throw new Error('googleTranslateMachine logError not implemented')
        },
      },
      services: {
        translate: () => {
          throw new Error('translate not implemented')
        },
      },
    },
  )
}

export type GoogleTranslateMachineInterpreter = Interpreter<
GoogleTranslateContext,
GoogleTranslateState,
GoogleTranslateEvent
>

export function googleTranslateService() {
  return interpret(
    returnGoogleTranslateMachine().withConfig({
      services: {
        translate: async ({ originalTexts }, event) => {
          if (event.type !== 'TRANSLATE') return Promise.reject('Invalid event')
          const form = new FormData()
          for (const t of originalTexts) {
            form.append('q', t)
          }
          form.append('target', event.targetLang)
          form.append('format', event.format)
          const response = await $fetch('/autotranslate/', {
            method: 'POST',
            body: form,
            timeout: 30000,
            baseURL,
          })
          return response.data.translations.map(
            ({ translatedText }) => translatedText,
          )
        },
      },
      actions: {
        logError: (_, _event: any) => {
          const event: ErrorPlatformEvent = _event
          event.data.name = `googleTranslateMachine ${event.type} error`
          sentryCapture(event.data)
        },
      },
    }),
  )
}
