
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import {
  GoogleTranslateState,
  googleTranslateService,
} from '../googleTranslateMachine'

@Component
export default class GoogleTranslateButton extends Vue {
  @Prop({ required: false })
    parent?: Element

  @Prop({ default: '' })
    querySelectorValue: string

  @Prop({ default: 'html' })
    format: 'text' | 'html'

  shouldShowButton = false

  mounted() {
    this.shouldShowButton = true
    this.googleTranslateMachine = googleTranslateService()
  }

  private googleTranslateMachine: GoogleTranslateState | null = null
  private nodes: Element[] = []
  currentStateValue: string = 'showingOriginal' //initial state from machine

  get isShowingOriginal() {
    return this.currentStateValue === 'showingOriginal'
  }

  get isShowingTranslated() {
    return this.currentStateValue === 'showingTranslated'
  }

  get isTranslating() {
    return this.currentStateValue === 'translating'
  }

  showContent(contentType: 'translated' | 'original') {
    this.ensureDataInitialized()
    contentType === 'translated' ? this.translate() : this.showOriginal()
  }

  private ensureDataInitialized() {
    if (!this.googleTranslateMachine) return
    if (this.googleTranslateMachine?.initialized) return
    this.nodes = this.getTranslatableNodes()
    this.googleTranslateMachine
      .onTransition((state) => {
        this.currentStateValue = state.value
        if (state.matches('showingTranslated')) {
          this.overrideTranslatableNodesWithTexts(state.context.translatedTexts)
        } else if (state.changed && state.matches('showingOriginal')) {
          this.overrideTranslatableNodesWithTexts(state.context.originalTexts)
        }
      })
      .start()
  }

  private getTranslatableNodes() {
    const nodes = this.parent
      ? this.parent.querySelectorAll('p,span,li')
      : document.querySelectorAll(
        `[data-g-translate="${this.querySelectorValue}"]`,
      )
    return Array.from(nodes)
  }

  private translate() {
    this.googleTranslateMachine.send('TRANSLATE', {
      format: this.format,
      texts: this.nodes.map((n) => n.innerHTML),
      targetLang: this.$i18n.locale,
    })
  }

  private showOriginal() {
    this.googleTranslateMachine.send('SHOW_ORIGINAL')
  }

  private overrideTranslatableNodesWithTexts(texts: string[]) {
    for (const [index, _c] of this.nodes.entries()) {
      this.nodes[index].innerHTML = texts[index]
    }
  }
}
